import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../components';
import { BarChart } from '@mui/x-charts';
import { getTotalTxnsByDayService } from 'services/panelService';
import moment from 'moment';
import { Grid, Grid2 } from '@mui/material';
import PanelTransactionsScreen from './PanelTransactionsScreen';
import PanelPaymentsScreen from './PanelPaymentsScreen';
import PanelBinnacleScreen from './PanelBinnacleScreen';

const PanelScreen = () => {
  const navigate = useNavigate();
  const [graph, setGraph] = useState({})
  useEffect(() => {
    getTotalTxnsByDayService().then((response) => {
      setGraph(response.reduce((acc, { _id, total }) => {
        const date = moment(_id).format('MM-DD');
        acc[date] = (acc[date] || 0) + total;
        return acc;
      }, {}))
    });
  }, []);

  return (
    <>
      <h2 className='title' style={{ textAlign: 'center' }}>Transacciones por día de los últimos 30 diás</h2>
      <BarChart
        yAxis={[]}
        xAxis={[{
          scaleType: 'band',
          data: Object.keys(graph),
          tickPlacement: 'middle'

        }]}
        series={[
          {
            type: 'bar',
            data: Object.values(graph)
          }
        ]}
        colors={['#1976d2']}
        grid={{ horizontal: true }}
        // borderRadius={10}
        // barLabel="value"
        // width={500}
        height={300}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <PanelTransactionsScreen />
        </Grid>
        <Grid item xs={12} sm={4}>
          <PanelPaymentsScreen />
        </Grid>
        <Grid item xs={12} sm={4}>
          <PanelBinnacleScreen />
        </Grid>

      </Grid>
      {/* <CustomTab
          panels={[
            {
              name: "Transacciones",
              route: '/panel/transacciones',
              onChange: () => navigate('transacciones'),
              content: <Outlet />
            },
            {
              name: "Abonos",
              route: '/panel/abonos',
              onChange: () => navigate('abonos'),
              content: <Outlet />
            },
            {
              name: "Bitácora",
              route: '/panel/bitacora',
              onChange: () => navigate('bitacora'),
              content: <Outlet />
            }
          ].filter(i => i)}
        /> */}
    </>
  )
}

export default PanelScreen;