import React, { useEffect, useState } from 'react'
import { CustomButton, FilterBar, TableData } from '../../components';
import DownloadIcon from "@mui/icons-material/Download";
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { useDispatch, useSelector } from 'react-redux';
import { setConciliacionesDiferenciasReport, setConciliacionesReport } from '../../redux/slices/report/reportSlice';
import { getProvidersService, getConciliacionesDiferenciasReportService, getConciliacionesReportService, downloadConciliacionesReportService } from '../../services/report/reportService';
import { errorAlert } from '../../helpers/alerts';
import moment from 'moment';
import { setTitle } from '../../redux/slices/uiSlice';
import { currencyFormatter, downloadFile } from '../../helpers/util';
import ColumnFilterModal from "../../components/modal/ColumnFilterModal";

const columns_diferencias = [
  { name: "provider", label: "Proveedor", format: ({ provider }) => provider },
  { name: "cred", label: "Instancia", format: ({ cred }) => cred },
  { name: "total_txns_conciliadas", label: "N Tx", format: ({ total_txns_conciliadas }) => total_txns_conciliadas },
  { name: "saldo_gastado", label: "Saldo Gastado", format: ({ saldo_gastado }) => currencyFormatter.format(saldo_gastado * -1) },
  { name: "total_txns_no_conciliadas", label: "N Tx", format: ({ total_txns_no_conciliadas }) => total_txns_no_conciliadas },
  { name: "saldo_conciliado", label: "Saldo Conciliado", format: ({ saldo_conciliado }) => currencyFormatter.format(saldo_conciliado * -1) },
  { name: "estatus", label: "Estatus", format: ({ saldo_conciliado }) => saldo_conciliado != 0 ? 'Conciliado' : 'Error' },
  { name: "monto_diff", label: "Monto Diferencia", format: ({ saldo_gastado, saldo_conciliado }) => saldo_gastado - saldo_conciliado }
]


const columns = [
  { category: 'Destino', name: "type", label: "Movimiento", format: ({ typeReg }) => typeReg },
  { category: 'Destino', name: "operator", label: "Operadora", format: ({ providerCarrier }) => providerCarrier },
  { category: 'Destino', name: "phone", label: "Telefono", format: ({ phoneNumber }) => phoneNumber },
  { category: 'Destino', name: "import", label: "Importe", format: ({ typeReg, Amount, operacionSaldo }) => currencyFormatter.format(typeReg === 'TAE' ? Amount : operacionSaldo) },
  { category: 'Destino', name: "region", label: "Región", format: ({ region }) => region },
  { category: 'Saldo', name: "prevBalance", label: "Saldo Anterior", format: ({ saldoAnterior }) => saldoAnterior ? currencyFormatter.format(saldoAnterior) : '' },
  { category: 'Saldo', name: "currentBalance", label: "Saldo Actual", format: ({ saldoActual }) => saldoActual ? currencyFormatter.format(saldoActual) : '' },
  { category: 'Saldo', name: "operacionSaldo", label: "Debitado", format: ({ operacionSaldo }) => currencyFormatter.format(operacionSaldo) },
  { category: 'Saldo', name: "discount_porcent", label: "% Descuento", format: ({ discount_porcent }) => discount_porcent ? `${discount_porcent}%` : '' },
  { category: 'Saldo', name: "discount_applied", label: "Descuento", format: ({ discount_applied }) => discount_applied ? currencyFormatter.format(discount_applied) : '' },
  { category: 'Destino', name: "reference", label: "Referencia", format: ({ typeReg, ProviderReference, referenceSaldo }) => typeReg === 'TAE' ? ProviderReference : referenceSaldo },
  { category: 'Resultado', name: "state", label: "Estado", format: ({ typeReg, ProviderResponseMessage }) => typeReg === 'TAE' ? ProviderResponseMessage : '' },
  { category: 'Resultado', name: "datetime", label: "Fecha y hora", format: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY HH:mm:ss') },
  { category: 'Resultado', name: "auth", label: "Autorización", format: ({ AuthCode }) => AuthCode },
  { category: 'Resultado', name: "code", label: "Codigo", format: ({ ProviderResponseCode }) => ProviderResponseCode },
  { category: 'Entidad', name: "bag", label: "Bolsa", format: ({ hosterBagId }) => hosterBagId.hashtag },
  { category: 'Entidad', name: "provider", label: "Proveedor", format: ({ serviceName }) => serviceName },
  { category: 'Conciliación', name: "conciliacion", label: "Conciliación", format: ({ conciliacion }) => conciliacion ? 'SI' : 'NO' },
  { category: 'Conciliación', name: "conciliacion_date", label: "Fecha de conciliación", format: ({ conciliacion_date }) => conciliacion_date > 0 ? moment(conciliacion_date).format('DD/MM/YYYY HH:mm:ss') : '--' },
]


const ConciliacionScreen = () => {
  const dispatch = useDispatch();
  const { conciliacionesDiferenciasReport, conciliacionesReport } = useSelector(store => store.reports);
  const [loading, setLoading] = useState(false)
  const [downloadFilters, setDownloadFilters] = useState({})
  const [providers, setProviders] = useState([])
  const [showColumnFilterModal, setShowColumnFilterModal] = useState(false)
  const [selectedColums, setSetSelectedColums] = useState([
    "type",
    "operator",
    "phone",
    "import",
    "currentBalance",
    "state",
    "datetime",
    "auth",
    "code",
    "provider",
    "conciliacion",
    "conciliacion_date"
  ])

  useEffect(() => {
    dispatch(setTitle('Conciliaciones'));
    setLoading(true)
    getProvidersService({})
      .then((response) => setProviders(response || []))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handlerSubmitCD = async (data) => {
    setLoading(true)
    setDownloadFilters(data)
    getConciliacionesDiferenciasReportService(data)
      .then((response) => dispatch(setConciliacionesDiferenciasReport(response || [])))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
  }

  const handlerSubmitC = async (data) => {
    setLoading(true)
    setDownloadFilters(data)
    getConciliacionesReportService(data)
      .then((response) => dispatch(setConciliacionesReport(response || [])))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
  }

  const handlerDownload = async () => {
    // setLoading(true)
    downloadConciliacionesReportService(downloadFilters)
      .then((data) => downloadFile(data, 'Conciliaciones.xlsx'))
      .catch((e) => errorAlert(e))
    // .finally(() => setLoading(false));
  }

  const handlerSelectColumn = (columns) => {
    setSetSelectedColums(columns)
    setShowColumnFilterModal(false)
  }

  return (
    <>
      <FilterBar
        handlerFilter={(data) => {
          handlerSubmitCD(data);
          handlerSubmitC(data);
        }}
        defaultValues={{
          startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }}
        filters={[
          {
            name: "startDate",
            label: "Fecha Inicio",
            type: "date",
          },
          {
            name: "endDate",
            label: "Fecha Fin",
            type: "date",
          },
          {
            name: 'provider',
            label: "Proveedores",
            type: "select",
            all: 'Todos',
            data: providers.filter(({ name }) => name !== 'smartgateiso'),
          }
        ]}
      />
      <TableData
        loading={loading}
        dataRows={conciliacionesDiferenciasReport || []}
        dataTitle={columns_diferencias.map(({ label }) => label)}
        formatter={(data) => columns_diferencias.map(({ format }) => format(data))}
        actions={[
        ]}
      />
      <div className="container-title">
        <div className="commerce" style={{ fontSize: 18 }}>Transacciones no conciliadas</div>
      </div>
      <TableData
        loading={loading}
        dataRows={conciliacionesReport || []}
        headerButtons={[
          <CustomButton
            icon={<ViewWeekIcon />}
            key="filterColumns"
            name={"Columnas"}
            additional_class={"columnFilterButton"}
            click={() => setShowColumnFilterModal(true)}
          />,
          <CustomButton
            icon={<DownloadIcon />}
            key="dowloadBalance"
            name={"Descargar"}
            click={handlerDownload}
          />,
        ]}
        dataTitle={columns.filter(({ name }) => selectedColums.includes(name)).map(({ label }) => label)}
        formatter={(data) => columns.filter(({ name }) => selectedColums.includes(name)).map(({ format }) => format(data))}
        actions={[
        ]}
      />
      <ColumnFilterModal
        open={showColumnFilterModal}
        columns={columns}
        selected={selectedColums}
        onSuccess={handlerSelectColumn}
        onClose={() => setShowColumnFilterModal(false)}
      />
    </>

  )
}

export default ConciliacionScreen