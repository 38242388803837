import moment from "moment";
import React, { useEffect, useState } from "react";
import { FilterBar, TableData } from "../../components";
import { errorAlert } from "../../helpers/alerts";
import { getBinnacleService } from "../../services/panelService";

const PanelBinnacleScreen = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [types, setTypes] = useState([])

  // useEffect(() => {
  //   setTypes(list
  //     .map(({ event: id, event: name }) => ({ id, name }))
  //     .filter(({ id }, index) => index === list.findIndex(o => id === o.event))
  //   );
  // }, [list])
  useEffect(() => {
    getBinnacleService({ page: 0, limit: 300 })
      .then((response) => setList(response))
      .catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }, []);
  // const handlerSubmit = async (data) => {
  //   setLoading(true)
  //   getBinnacleService({ ...data, page: 0, limit: 300 })
  //     .then((response) => setList(response))
  //     .catch((error) => errorAlert(error))
  //     .finally(() => setLoading(false));
  // }

  return (
    <TableData
      title={"Bitácora"}
      autonumber={false}
      pagination={false}
      loading={loading}
      dataRows={list.map((item) => ({
        ...item,
        style: {
          fontSize: 12,
          padding: 1,
        }
      }))}
      dataTitle={[
        "Hora",
        "Tipo",
        "Concepto",
        "Usuario",
      ]}
      formatter={({
        createdAt,
        event,
        description,
        userName
      }) => ([
        moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
        event,
        description,
        userName
      ])}
    />
  );
};

export default PanelBinnacleScreen;
