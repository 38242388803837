import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MenuHeader.css';
import { cleanSession, setSession } from '../../redux/slices/sessionSlice';
import { currencyFormatter } from '../../helpers/util';
import { getBagsService, getSessionBagsService } from '../../services/client/bagService';
import { ChevronLeft, HideImageRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { showSidebar } from '../../redux/slices/uiSlice';
import Image from '../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import IconUser from './IconUser';
import theme from '../../config/theme';

const MenuHeader = ({ loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { hoster, nameUser, rol, email, bags, updateBalance } = useSelector(store => store.session);
  const [hosterName, setHosterName] = useState('');
  const [showBalance, setShowBalance] = useState(false)

  useEffect(() => {
    if (location.search === '?session_expired')
      return;
    if (hoster)
      getSessionBagsService().then(bags => {
        dispatch(setSession({
          bags: bags.map(bag => ({
            id: bag.id,
            type: bag.type,
            balance: bag.saldo,
          }))
        }))
        setHosterName(bags[0]?.hoster?.username)
      }).catch((e) => console.error(e))
    // eslint-disable-next-line
  }, [hoster, updateBalance])

  const closeSession = () => {
    dispatch(cleanSession())
    navigate('/')
  }

  return (
    <div className='menu-header'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='menu-sidebar-button' onClick={() => dispatch(showSidebar(false))}>
          <ChevronLeft className='sidebar-button-icon' />
        </div>
        <div className='container-header-logo'>
          <img alt='logo' className='header-logo' src={Image[theme].logo} />
        </div>
      </div>
      {
        loading ? (
          <Grid container direction={'row'} columnSpacing={2} alignItems={'center'}>
            <Grid item >
              <Skeleton variant="rectangular" width={210} height={25} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={15} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={15} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={25} />
            </Grid>
          </Grid>
        ) : (
          <div className='menu-header-wrapper'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconUser name={hoster ? hosterName : nameUser} />
            </div>
            <div className='menu-header-info'>
              <span className='menu-header-info-name'>{hoster ? hosterName : nameUser}</span>
              <span className='menu-header-info-name'>{email}</span>
              <span className='menu-header-info-user'>{rol?.name}</span>
              {
                bags?.length > 0 && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flex: 3, flexDirection: 'column' }}>
                      {
                        bags?.map(bag => (
                          <span key={bag.id} className='menu-header-info-bag' style={{ flex: 1 }} >Saldo {bag.type}: {showBalance ? currencyFormatter.format(bag.balance) : '**********'}</span>
                        ))
                      }
                    </div>
                    <div style={{ flex: 1, alignContent: 'center', padding: '0 20px', cursor: 'pointer' }} onClick={() => setShowBalance(!showBalance)}>
                      {showBalance ? <VisibilityOff /> : <Visibility />}
                    </div>
                  </div>
                )
              }
              <span className='menu-header-info-close-session' onClick={closeSession}>Cerrar sesión</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MenuHeader;